import React, { Component } from 'react'
import { ThreeDots, BallTriangle  } from 'react-loader-spinner'
import Lottie from 'react-lottie'
import animationData from './9811-loading.json'

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
      isPaused: false
    }
    // this.handleStop = this.handleStop.bind(this)
  }

  // handleStop(){
  //   this.setState({isStopped:!this.state.isStopped})
  // }

  render(){
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div className="controlled">
        {/*<Lottie options={defaultOptions}
              height={100}
              width={100}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}
        />*/}
         {/* <img src="/img/lms_loader2.gif"/> */}
         {/* <ThreeDots 
          height="80" 
          width="80" 
          radius="9"
          color="#ED1B24" 
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
          /> */}
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#ED1B24"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
      </div>
    )
  }
}

export default Loader